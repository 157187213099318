<template>
  <div id="app">
    <div class="container">
      <div class="content">
        <div class="qq" @click="copy('qq')"></div>
        <div class="wechat" @click="copy('wechat')"></div>
        <div class="aff" @click="goto()"></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  data () {
    return {
      platformUrl: '',
      affID: 3170,
    }
  },
  created () {
    this.getEnv();
  },
  computed: {
    isMobile() {
      const regexpText = 'iPhone|iPod|iPad|\biOS-universal(?:.+)Mac\b|\bAndroid(?:.+)Mobile\b|Android|(?:SD4930UR|\bSilk(?:.+)Mobile\b)|Silk|Windows Phone|\bWindows(?:.+)ARM\b|BlackBerry|BB10|Opera Mini|\b(CriOS|Chrome)(?:.+)Mobile|Mobile(?:.+)Firefox\b';
      const regexp = new RegExp(regexpText, 'i');
      return regexp.test(navigator.userAgent);
    },
  },
  methods: {
    copy(param) {
      let value;
      const el = document.createElement('textarea');
      el.readOnly = true;
      switch (param) {
        case 'qq':
          value = '152539957'
          el.value = value;
          document.body.appendChild(el);
          el.select();
          document.execCommand('copy');
          el.blur();
          document.body.removeChild(el);
          window.alert('复制成功');
          break;
        case 'wechat':
          value = 'X134934'
          el.value = value;
          document.body.appendChild(el);
          el.select();
          document.execCommand('copy');
          el.blur();
          document.body.removeChild(el);
          window.alert('复制成功');
          break;
        default:
          window.location = 'https://www.18luckxl.com/register?affi=3170'
      }
    },
    async getEnv() {
      const VUE_APP_ENV_JSON = 'https://cdn.xinli18app.com/env.json';
      const res = await fetch(VUE_APP_ENV_JSON).then((res) => res.json());
      this.platformUrl = this.isMobile ? res.mobileWeb : res.platformUrl;
    },
    goto() {
      window.location = `http://iu7761.com/?affi=${this.affID}`;
    },
  }
}
</script>

<style lang="scss">
@import '../src/style/_variables';

* {
  margin: 0;
  padding: 0;
}

body, html, #app {
  position: relative;
  overflow-x: hidden;
  height: 100vh;
  width: 100vw;
}

.container {
  position: absolute;
  width: 1920px;
  height: 100vh;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  overflow-x: hidden;
}
// PC
.content {
  margin: 0 auto;
  position: relative;
  width: 1920px;
  height: 1500px;
  background: url('../src/assets/PC.jpg') center center / contain  no-repeat;
}

.qq, .wechat {
  position: absolute;
  top: 1163px;
  z-index: 1;
  width: 133px;
  height: 75px;
  background: url('../src/assets/copyBtn.png') center center / contain no-repeat;
}

.qq {
  left: 805px;
}

.wechat {
  left: 1300px;
}

.aff {
  position: absolute;
  top: 350px;
  left: 24%;
  z-index: 1;
  width: 558px;
  height: 94px;
  background: url('../src/assets/goOfficialBtn.png') center center / contain no-repeat;
}
// 1200 ~ 768
@media (max-width: #{$pcrwd-breakpoint + 'px'}) {
  .content {
    width: vw(1920px);
    height: vw(1500px);
  }

  .qq, .wechat {
    top: vw(1163px);
    width: vw(133px);
    height: vw(75px);
  }

  .qq {
    left: vw(805px);
  }

  .wechat {
    left: vw(1300px);
  }

  .aff {
    top: vw(350px);
    width: vw(558px);
    height: vw(94px);
  }
}
// H5
@media (max-width: #{$mblrwd-breakpoint + 'px'}) {

  .content {
    width: vwm(768px);
    height: vwm(2700px);
    background: url('../src/assets/H5.jpg') center center / contain no-repeat;
  }

  .qq, .wechat {
    width: vwm(176px);
    height: vwm(96px);
    left: vwm(525px);
  }

  .qq {
    top: vwm(1910px);
  }

  .wechat {
    top: vwm(2025px);
  }

  .aff {
    left: 50%;
    transform: translateX(-50%);
    top: vwm(720px);
    width: vwm(558px);
    height: vwm(94px);
  }
}
</style>
